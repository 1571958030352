@import '../../../styles/customMediaQueries.css';


.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.content {
  margin: 0 24px 0 24px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: 986px;
    width: 100%;
    padding: 0;
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  margin-top: 60px;

  @media (--viewportLarge) {
    font-size: 51px;
    line-height: 63px;
  }
}

.heroSubTitle {
  composes: h4 from global;

  color: var(--matterColorLight);
  margin: 20px 0 40px 0;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  width: 100%;
  letter-spacing: -0.11px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 0 98px 0;
  }

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.carouselHolder {
  position: relative;
  background: linear-gradient(180deg, #48C9F7 0%, #4871F7 100%);
  display: flex;
  flex-direction: column;
  border-radius: 12px;

  @media (--viewportLarge) {
    flex-direction: row;

    &:hover,
    &:active,
    &:focus {
      .itemCarouselLink {
        background: white;
        color: var(--hcbrandBlue);
        text-decoration: none;

        .rightArrow {
          background-image: url("../images/arrows/rightBlue.svg");
        }
      }
    }
  }


}

.itemCarousel {
  display: flex;
  flex-direction: row;
  color: white;
  border-radius: 12px;
  padding: 0 24px;
  text-align: left;

  @media (--viewportLarge) {
    height: 517px;
    padding: 0 40px 30px;
  }
}

.itemCarouselTitleHolder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 30px;
  position: relative;

  @media (--viewportLarge) {
    padding-top: 84px;
  }
}

.itemCarouselTitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 0;
}

.itemCarouselDescription {
  margin-top: 6px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.12px;
}

.itemCarouselLink {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 28px;
  letter-spacing: -0.12px;
  padding: 0 20px;
  color: white;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
  left: -20px;
  transition: all 0.3s ease-in-out;

  @media (--viewportLarge) {
    font-size: 20px;
    position: absolute;
    bottom: 8px;
    justify-content: center;
    &:hover,
    &:active,
    &:focus {
      background: white;
      color: var(--hcbrandBlue);
      text-decoration: none;

      .rightArrow {
        background-image: url("../images/arrows/rightBlue.svg");
      }
    }
  }


}

.rightArrow {
  width: 15px;
  height: 50px;
  margin-left: 10px;
  background: url("../images/arrows/right.svg") 0 50% no-repeat;
}

.itemCarouselImgHolder {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemCarouselImg {
  max-width: 75%;
  width: 100%;
}


.counter {
  color: white;
  line-height: 30px;
}

.buttonsHolder {
  display: flex;
  position: relative;
  justify-content: center;
  bottom: 20px;
  padding-top: 30px;

  @media (--viewportLarge) {
    bottom: 40px;
    padding-bottom: 0;
  }
}

.buttonPrev {
  border: none;
  width: 70px;
  height: 30px;
  cursor: pointer;

  & .buttonArrow {
    width: 13px;
  }
}

.buttonNext {
  border: none;
  width: 70px;
  height: 30px;
  cursor: pointer;

  & .buttonArrow {
    width: 14px;
  }
}

.items {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100vw;


  @media (--viewportLarge) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.thumbs {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & :global(.alice-carousel__stage-item.__active img) {
    /*height: 330px;*/
    /*width: 350px;*/
  }

  & :global(.alice-carousel__stage-item) {
    /*border-radius: 12px;*/
  }

  @media (--viewportLarge) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.thumbItem {
  border-radius: 12px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportLarge) {
    padding: 20px;
  }
}

.thumbImg {
  width: 70%;
  height: 260px;

  @media (--viewportLarge) {
    height: 280px;
    width: 400px;
  }
}





