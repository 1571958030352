@import '../../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 280px;
}

.hero {
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 32px;
  transition: all 0.3s ease-in-out;


  @media (--viewportMedium) {
    padding-bottom: 83px;
    justify-content: flex-end;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
    margin-bottom: 90px;
    background: url('../images/hero/leftSide.png') left bottom no-repeat, url('../images/hero/rightSide.png') right bottom no-repeat;
    background-color: transparent;
  }
}

.hero50 {
  background-size: 20%;
}

.hero100 {
  background-size: 22%;
}

.hero150 {
  background-size: 24%;
}

.hero200 {
  background-size: 26%;
}

.heroContent {
  margin: 0 24px 0 24px;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  @media (--viewportLarge) {
    position: relative;
    flex-direction: row;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 76px 36px 0 36px;
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--colorBlack100);
  font-weight: 600;
  margin-bottom: 23px;
  font-size: 30px;
  max-width: 200px;
  line-height: 27px;

  @media (--viewportLarge) {
    max-width: 100%;
    font-size: 81px;
    line-height: 91px;
    margin-bottom: 30px;
  }
}


.boldTitle {
  font-weight: 800;
}


.heroSubTitle {
  composes: h4 from global;
  margin: 0 0 90px 0;
  font-size: 18px;
  font-weight: 500;
  max-width: 200px;
  color: #231F20;
  line-height: 19px;
  position: relative;
  z-index: 1;

  @media (--viewportMedium) {
    margin: 0 0 38px 0;
    font-size: 20px;
    line-height: 32px;
    max-width: 400px;
  }

  @media (--viewportLarge) {

  }
}


.heroButton {
  composes: buttonPrimary from global;
  padding: 8px 0;
  background: var(--colorBlack100);
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  min-height: 41px;
  font-size: 16px;
  max-width: 250px;

  @media (--viewportLarge) {
    max-width: 300px;
    font-size: 20px;
    height: 50px;
    min-height: 50px;
  }
}

.heroDescription {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  max-width: 100%;
  order: 2;
  position: relative;
  z-index: 2;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    max-width: 50%;
    order: 1;
  }
}

.heroImageHolder {
  order: 1;
  position: absolute;
  z-index: 1;
  right: 0;
  transition: all 0.3s ease-in-out;
  top: -24px;


  @media (--viewportLarge) {
    display: block;
    max-width: 67%;
    position: absolute;
    right: -21px;
    top: -15px;
    order: 2;
  }
}

.scroll50 {
  @media (--viewportLarge) {
    max-width: 64%;
  }
}

.scroll100 {
  @media (--viewportLarge) {
    max-width: 60%;
  }
}

.scroll150 {
  @media (--viewportLarge) {
    max-width: 57%;
  }
}

.scroll200 {
  @media (--viewportLarge) {
    max-width: 55%;
  }
}

.heroImage {
  max-width: 400px;

  @media (--viewportLarge) {
    max-width: 100%;
  }

}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.heroCategories {
  display: flex;
  align-items: center;
}

.heroCategoriesHolder {
  position: relative;
  padding: 0;
}

.heroCategoriesMenuContent {
  position: absolute;
  left: auto !important;
  right: 0 !important;
  margin: 0;
  max-width: 200px;
  width: 100%;

  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 0 0 2px 2px;
  transition: var(--transitionStyleButton);
  z-index: var(--zIndexPopup);

  & > div {
    display: none;
  }
}

.heroCategoriesMenuItem {
  padding: var(--marketplaceButtonSmallDesktopPadding);
  font-size: 16px;
  line-height: 1.4;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: var(--marketplaceColor);
  }
}

.heroCategoriesBtn {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  min-width: 170px;
  letter-spacing: 0.2px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--matterColorNegative);
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: 40px;
  min-height: 0;
  border-radius: var(--borderRadius) 0 0 var(--borderRadius);
  text-align: left;
  font-size: 16px;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    /* background-color: var(--matterColorLight); */
    /* border-color: transparent; */
    text-decoration: none;
    box-shadow: none;
  }

  @media (--viewportMedium) {
    min-width: 200px;
  }
}
