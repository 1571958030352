@import '../../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.content {
  margin: 0 24px 0 24px;

  text-align: center;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }


  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: 986px;
    width: 100%;
    padding: 140px 0 0;
  }
}

.mainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  font-weight: 600;
  text-align: center;
  font-size: 30px;
  line-height: 33px;
  margin-top: 60px;
  padding: 0 20px;

  @media (--viewportLarge) {
    font-size: 51px;
    line-height: 63px;
  }

}

.subTitle {
  composes: h4 from global;
  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  line-height: 24px;
  letter-spacing: -0.11px;
  font-size: 15px;
  padding: 0 20px;
  font-weight: 500;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }

  @media (--viewportLarge) {
    font-size: 17px;
  }
}

.itemsList {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 0;

  @media (--viewportLarge) {
    flex-direction: row;
    margin-top: 110px;
  }
}

.itemHolder {
  background: #FF602F;
  border-radius: 12px;
  color: white;
  padding: 24px;
  text-align: left;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 20px;
  position: relative;

  &:nth-child(2n){
    background: #FCB900;
    color: var(--colorBlack100);

    & .itemImageHolder {
      @media (--viewportLarge) {
        position: relative;
        top: -45px;
      }
    }


  }

  @media (--viewportLarge) {
    flex: 0 0 49%;
    max-width: 49%;
    padding: 20px 40px;

    &:hover,
    &:active,
    &:focus {
      & .itemImageHolder {
        transform: scale(1.1);
      }

      .itemLink {
        background-color: white;
        color: #e4582e;
        text-decoration: none;

        .rightArrow {
          background-image: url("../images/arrows/rightRed.svg");
        }
      }

      .itemLinkSecond {
        background: var(--colorBlack100);
        color: white;

        .rightArrow {
          background-image: url("../images/arrows/right.svg");
        }
      }
    }
  }


}

.itemTitleHolder {

}

.itemTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  width: 100%;

  @media (--viewportLarge) {
    width: 71%;
    font-size: 28px;
    line-height: 35px;
  }
}

.itemDescription {
  font-weight: 500;
  letter-spacing: -0.11px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;

  @media (--viewportLarge) {
    width: 58%;
    font-size: 18px;
    line-height: 25px;
  }
}

.itemImageHolder {
  display: flex;
  justify-content: flex-end;
  transition: all 0.3s ease-in-out;
  margin-bottom: 27px;

  @media (--viewportLarge) {
    position: relative;
    top: -70px;
  }

  &:hover,
  &:active,
  &:focus {
    transform: scale(1.1);
    cursor: pointer;
  }

}

.itemImage {
  max-width: 110px;
  @media (--viewportLarge) {
    max-width: 100%;
  }
}

.itemLink {
  color: white;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 0 20px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  max-width: 300px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;

  @media (--viewportLarge) {
    font-size: 20px;
    bottom: 34px;
    left: 20px;
  }
}

.rightArrow {
  width: 15px;
  height: 50px;
  margin-left: 10px;
  background: url("../images/arrows/right.svg") 0 50% no-repeat;
}


.itemLinkSecond {
  color: var(--colorBlack100);

  & .rightArrow {
    background: url("../images/arrows/rightBlack.svg") 0 50% no-repeat;
  }
}


