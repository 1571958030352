@import '../../../styles/customMediaQueries.css';

.root {
  composes: defaultBackgroundImage from global;


  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.content {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: 986px;
    width: 100%;
    padding: 125px 0 0;
  }
}

.mainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  font-size: 30px;
  font-weight: 600;
  line-height: 63px;
  text-align: center;
  margin-bottom: 13px;

  @media (--viewportLarge) {
    font-size: 51px;
  }
}


.subTitle {
  composes: h4 from global;
  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.11px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }
}

.carouselHolder {

  position: relative;
  margin-bottom: 120px;

  & :global(.alice-carousel__dots-item) {
    border: 1px solid var(--colorBlack100);
    background: transparent;

    @media (--viewportLarge) {
      width: 10px;
    }
  }

  & :global(.alice-carousel__dots-item.__active) {
    background: var(--colorBlack100);
  }

  @media (--viewportLarge) {
    margin-top: 103px;
  }

  &:hover,
  &:active,
  &:focus {
    .buttonsHolder {
      width: 105%;
      left: -2.5%;
    }

    .buttonPrev {
      background: hsla(0, 0%, 98%, 1);
      border-radius: 50%;
    }

    .buttonNext {
      background: hsla(0, 0%, 98%, 1);
      border-radius: 50%;
    }
  }
}

.itemCarousel {
  color: white;
  background: linear-gradient(180deg, #48C3F7 0%, #4871F7 100%);
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  margin: 0 10px;
  padding-top: 56px;
  position: relative;
  height: 285px;
}

.itemCarouselCounter {
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;
  text-align: center;
  margin-top: 50px;
}

.itemCarouselTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.12px;
  text-align: center;
}

.itemCarouselImg {

  max-width: 100%;
  margin: 0 auto;

  @media (--viewportLarge) {
    width: 117px;
  }
}

.dataCarouselHolder {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.buttonsHolder {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: calc(50% - 24px);
  z-index: 100;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;

  @media (--viewportLarge) {
    width: 110%;
    left: -5%;
  }
}

.buttonPrev {
  border: none;
  width: 70px;
  height: 70px;
  cursor: pointer;

  & .buttonArrow {
    width: 13px;
  }
}

.buttonNext {
  border: none;
  width: 70px;
  height: 70px;
  cursor: pointer;

  & .buttonArrow {
    width: 14px;
  }
}

.buttonArrow {

}
