@import '../../styles/customMediaQueries.css';

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.root {
  width: 100%;
  margin: 0 auto;
  background: hsla(0, 0%, 98%, 1);



  @media (--viewportLargeWithPaddings) {

  }

}

.main {
  display: block !important;
}


/* heroContainer gives the height for SectionCategories */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;

}

.hero {
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 32px;
  background-color: #FCB900;

  @media (--viewportMedium) {
    padding-bottom: 83px;
    justify-content: flex-end;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
    margin-bottom: 190px;
    background: url('./images/hero/leftSide.png') left bottom no-repeat, url('./images/hero/rightSide.png') right bottom no-repeat;
    background-color: transparent;
  }
}
