@import '../../../styles/customMediaQueries.css';

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content {
  margin: 24px;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  @media (--viewportLarge) {
    height: 517px;
    flex-direction: row;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      .link {
        background: hsla(226, 92%, 63%, 1);
        color: white;
        text-decoration: none;

        .rightArrow {
          background-image: url("../images/arrows/right.svg");
        }
      }
    }
  }

  @media only screen and (min-width: 1367px) {
    margin: 25px auto 0;
    max-width: 986px;
    width: 100%;
    padding: 40px;
  }


}

.mainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);

  font-size: 25px;
  font-weight: 600;
  line-height: 35px;

  @media (--viewportLarge) {
    font-size: 28px;
  }
}

.subTitle {
  composes: h4 from global;
  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.12px;
  text-align: left;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }

  @media (--viewportLarge) {
    font-size: 18px;
  }
}

.block {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 0px;

  @media (--viewportLarge) {
    flex: 0 0 51%;
    max-width: 51%;
    padding-top: 73px;
  }
}

.logosHolder {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 20px 0;
  margin-top: 20px;

  @media (--viewportLarge) {
    flex: 0 0 49%;
    max-width: 49%;
    padding: 40px 0;
  }
}

.logoItem {
  flex: 0 0 45%;
  max-width: 45%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 183px;
  height: 41px;
  margin: 0 5px 10px;

  @media (--viewportMedium) {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0;
  }

  @media (--viewportLarge) {
    justify-content: center;
  }

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
  }
}

/*parnters logos*/
.wicklowhire {
  background: url('../images/partners/wicklow.svg') 50% 50% no-repeat;
  background-size: contain;

  &:hover,
  &:active,
  &:focus {
    background-image: url('../images/partners/wicklow_color.svg');
  }
}

.expert {
  background: url('../images/partners/expert.svg')  50% 50%  no-repeat;
  background-size: contain;

  &:hover,
  &:active,
  &:focus {
    background-image: url('../images/partners/expert_color.svg');
  }
}

.rota {
  background: url('../images/partners/rota.svg')  50% 50%  no-repeat;
  background-size: contain;
  height: 73px;

  &:hover,
  &:active,
  &:focus {
    background-image: url('../images/partners/rota_color.svg');
  }
}

.builders_hoists {
  background: url('../images/partners/builders_hoists.svg')  50% 50%  no-repeat;
  background-size: contain;
  height: 74px;

  &:hover,
  &:active,
  &:focus {
    background-image: url('../images/partners/builders_hoists_color.svg');
  }
}

.cyril_j {
  background: url('../images/partners/cyril_j.svg')  50% 50%  no-repeat;
  background-size: contain;
  height: 60px;

  &:hover,
  &:active,
  &:focus {
    background-image: url('../images/partners/cyril_j_color.svg');
  }
}

.kells_hire {
  background: url('../images/partners/kells_hire.svg')  50% 50%  no-repeat;
  background-size: contain;
  height: 65px;

  &:hover,
  &:active,
  &:focus {
    background-image: url('../images/partners/kells_hire_color.svg');
  }
}

.sme {
  background: url('../images/partners/sme.svg')  50% 50%  no-repeat;
  background-size: contain;
  height: 70px;

  &:hover,
  &:active,
  &:focus {
    background-image: url('../images/partners/sme_color.svg');
  }
}

.vms {
  background: url('../images/partners/vms.svg')  50% 50%  no-repeat;
  background-size: contain;
  height: 70px;

  &:hover,
  &:active,
  &:focus {
    background-image: url('../images/partners/vms_color.svg');
  }
}

.link {
  color: #4871F7;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.12px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
  left: -20px;
  transition: all 0.3s ease-in-out;

  @media (--viewportLarge) {
    font-size: 20px;
    position: absolute;
    bottom: 10px;
    justify-content: center;

    &:hover,
    &:active,
    &:focus {
      background: hsla(226, 92%, 63%, 1);
      color: white;
      text-decoration: none;

      .rightArrow {
        background-image: url("../images/arrows/right.svg");
      }
    }
  }

}

.rightArrow {
  width: 15px;
  height: 50px;
  margin-left: 10px;
  background: url("../images/arrows/rightBlue.svg") 0 50% no-repeat;
}


