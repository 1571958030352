@import '../../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

}

.content {
  margin: 20px 24px 0 24px;
  background: white;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    height: 517px;

    &:hover,
    &:active,
    &:focus {
      .itemCarouselLink {
        background: hsla(226, 92%, 63%, 1);
        color: white;
        text-decoration: none;

        .rightArrow {
          background-image: url("../images/arrows/right.svg");
        }
      }
    }
  }

  @media only screen and (min-width: 1367px) {
    margin: 20px auto 0;
    max-width: 986px;
    width: 100%;
    padding: 0;
  }


}

.itemCarousel {
  display: flex;
  flex-direction: row;
  padding: 20px;
  position: relative;

  @media (--viewportLarge) {
    padding: 40px;
    height: 517px;
  }
}

.itemCarouselTitleHolder {
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 40px;
  }
}

.itemCarouselTitle {
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;
  color: hsla(345, 6%, 13%, 1);
  margin-top: 40px;
  margin-bottom: 9px;

  @media (--viewportLarge) {
    padding-right: 50px;
    margin-top: 96px;
  }
}

.itemCarouselDescription {
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.12px;
  margin: 0;

  @media (--viewportLarge) {
    padding-right: 27px;
  }
}

.itemCarouselLink {
  color: hsla(226, 92%, 63%, 1);
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.12px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
  left: -20px;
  padding: 0 20px;
  margin: 20px 0;
  width: 100%;
  transition: all 0.3s ease-in-out;

  @media (--viewportLarge) {
    font-size: 20px;
    position: absolute;
    bottom: 60px;
    left: 20px;
    margin: 0;
    width: auto;
    justify-content: center;

    &:hover,
    &:active,
    &:focus {
      background: hsla(226, 92%, 63%, 1);
      color: white;
      text-decoration: none;

      .rightArrow {
        background-image: url("../images/arrows/right.svg");
      }
    }
  }


}

.countryName {
  margin-left: 5px;
  display: inline-block;
}

.rightArrow {
  width: 15px;
  height: 50px;
  margin-left: 10px;
  background: url("../images/arrows/rightBlue.svg") 0 50% no-repeat;
}

.items {
  /*flex: 0 0 100%;*/
  /*max-width: 100%;*/
  /*width: 100vw;*/
  position: relative;


  @media (--viewportLarge) {
    /*flex: 0 0 50%;*/
    /*max-width: 50%;*/
  }
}


.thumbs {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 24px;
  position: relative;

  & :global(.alice-carousel__stage-item.__active img) {
    @media (--viewportLarge) {
      height: 330px;
      width: 350px;
    }

  }

  & :global(.alice-carousel__stage-item) {
    border-radius: 12px;
  }


  @media (--viewportLarge) {
    padding: 0 10px 0;
    flex: 0 0 50%;
    max-width: 514px;
    margin: 60px auto 30px;
  }
}

.thumbItem {
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportLarge) {
    padding: 20px;
    height: 330px;
    width: 350px;
  }
}

.thumbImg {

  border-radius: 12px;
  @media (--viewportLarge) {
    height: 288px;
    width: 288px;
  }
}

.buttonsHolder {
  display: flex;
  justify-content: center;
  padding: 30px 0;

  @media (--viewportLarge) {
    position: absolute;
    bottom: 40px;
    left:  calc(50% - 158px);
    padding: 0;
  }
}

.buttonPrev {
  border: none;
  width: 70px;
  height: 30px;
  cursor: pointer;

  & .buttonArrow {
    width: 13px;
  }
}

.buttonNext {
  border: none;
  width: 70px;
  height: 30px;
  cursor: pointer;

  & .buttonArrow {
    width: 14px;
  }
}

.buttonArrow {

}

.counter {
  color: var(--colorBlack100);
  line-height: 30px;
}
